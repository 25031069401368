<template>
	<div class="personal" data-view>
		<b-row class="alumnusContainer">
			<div class="topImg">
				<img loading="lazy" alt="Banner Personal" src="https://clubejecutivo.s3.us-east-2.amazonaws.com/assets/images/pageheads/team-header.jpg" />
			</div>
		</b-row>
		<b-row>
			<div class="container">
				<div class="mb-4">
					<p class="title">
						<font-awesome-icon :icon="['fas', 'user-tie']" class="socialIcon" />
						<span> SERVICIOS</span>
					</p>
					<div class="col-12 col-lg-4 threeCols">
						<ul class="uList">
							<li><a href="#">Campus virtual</a></li>
							<li><a href="#">videoformación</a></li>
							<li><a href="#">Buzón de Surgencias</a></li>
							<li><a href="#">Editorial</a></li>
							<li><a href="#">Otros Servicios</a></li>
						</ul>
					</div>
					<div class="col-12 col-lg-4 threeCols">
						<ul class="uList">
							<li><a href="#">Plan de Formación</a></li>
							<li><a href="#">Evaluación</a></li>
							<li><a href="#">Investigación</a></li>
							<li><a href="#">Idiomas</a></li>
						</ul>
					</div>
					<div class="col-12 col-lg-4 threeCols">
						<ul class="uList">
							<li><a href="#">Calidad</a></li>
							<li><a href="#">Biblioteca</a></li>
							<li><a href="#">Diversidad</a></li>
							<li><a href="#">Carrera profesional</a></li>
						</ul>
					</div>
				</div>

				<div>
					<p class="title">
						<font-awesome-icon :icon="['fas', 'chalkboard-teacher']" class="socialIcon" />
						<span>SOPORTE</span>
					</p>
					<div class="col-12 col-lg-4 threeCols">
						<ul class="uList">
							<li><a href="#">Secretaría</a></li>
							<li><a href="#">Certificaciones</a></li>
							<li><a href="#">Mentoría</a></li>
						</ul>
					</div>
					<div class="col-12 col-lg-4 threeCols">
						<ul class="uList">
							<li><a href="#">Herramientas</a></li>
							<li><a href="#">Software</a></li>
							<li><a href="#">Innovación</a></li>
						</ul>
					</div>
					<div class="col-12 col-lg-4 threeCols">
						<ul class="uList">
							<li><a href="#">Biblioteca</a></li>
							<li><a href="#">Buzón de Sugerencias</a></li>
							<li><a href="#">Otros Servicios</a></li>
						</ul>
					</div>
				</div>
			</div>
		</b-row>
	</div>
</template>

<script>
export default {
	metaInfo() {
		return {
			meta: [
				{
					vmid: 'description',
					name: 'description',
					content:
						'El Servicio y soporte Personal de Aicad ofrece plan de formacion,evaluacion,investigacion,calidad,y diversidad de carrera profesional',
				},
			],
		};
	},
};
</script>

<style scoped>
.alumnusContainer {
	width: 100%;
	margin: 0px;
}

.alumnusContainer .topImg {
	margin-left: auto;
	margin-right: auto;
}

.topImg {
	width: 100%;
	background: #e4e4e4;
}

ul.uList li {
	background: url(https://clubejecutivo.s3.us-east-2.amazonaws.com/assets/images/icons/bullet_programas.png) no-repeat left 12px;
	padding: 3px 15px;
	margin-left: 3%;
	list-style-type: none;
}

.container {
	margin-top: 50px;
	margin-bottom: 50px;
}

ul.uList li a {
	color: #2d2d2d;
	font-size: 10pt;
}

.title {
	font-weight: 300;
	font-size: 20pt;
	color: #232323;
	font-family: 'open sans condensed', Arial, sans-serif;
	text-align: left;
	margin-left: 25px;
}

.threeCols {
	display: inline-block;
	vertical-align: top;
	text-align: left;
	margin-bottom: -15px;
}

.rowas {
	margin-bottom: 30px;
}

@media screen and (max-width: 1199px) {
	.alumnusContainer .topImg img {
		width: 100%;
	}
}
</style>
